import { faFacebookSquare, faInstagramSquare, faLinkedin, faTwitterSquare } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from '../assets/logo.png';

const FooterSection = () => {
  return (
    <footer className="border-t border-neutral-700">
      <div className="w-full py-6">
        <div className="container mx-auto text-center">
          <div className="flex items-center justify-center space-x-6 mb-4">
            <div className="flex-grow border-t-2 border-white"></div>
            <a href="#" className="text-white hover:text-blue-500 pointer-events-none">
              <FontAwesomeIcon className="text-2xl" icon={faFacebookSquare} />
            </a>
            <a href="#" className="text-white hover:text-pink-500 pointer-events-none">
              <FontAwesomeIcon className="text-2xl" icon={faInstagramSquare} />
            </a>
            <a href="#" className="text-white hover:text-red-500 pointer-events-none">
              <FontAwesomeIcon className="text-2xl" icon={faTwitterSquare} />
            </a>
            <a href="#" className="text-white hover:text-sky-500 pointer-events-none">
              <FontAwesomeIcon className="text-2xl" icon={faLinkedin} />
            </a>
            <div className="flex-grow border-t-2 border-white"></div>
          </div>
          <div className="mb-4 text-white ">
            <img
              className="h-14 w-14 mx-auto bg-white rounded-lg p-1 mb-3"
              src={logo}
            />
            <p>Copyright © 2024 Smart Connect X. All rights reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterSection;
