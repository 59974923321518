import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelopesBulk } from '@fortawesome/free-solid-svg-icons';

const PolicySection = () => {
  return (
    <div id="policy">
      <div className="relative mt-20 min-h-[1150px]">
        <div className="text-center">
          <h2 className="text-3xl sm:text-5xl lg:text-6xl mt-10 lg:mt-20 tracking-wide">
            Anti Spam{" "}
            <span className="bg-gradient-to-r from-cyan-500 to-cyan-600 text-transparent bg-clip-text">
              Policy
            </span>
          </h2>
        </div>
        <div className="flex flex-wrap mt-10 lg:mt-20">
          <div data-aos="fade-down-right" data-aos-duration="1000" data-aos-easing="ease-in-linear" className="w-full sm:w-1/2 lg:w-1/3 p-2 flex">
            <div className="flex-grow p-6 cursor-pointer transform transition duration-500 hover:scale-105 bg-white">
              <p className="text-4xl text-center mb-4">
                <FontAwesomeIcon icon={faEnvelopesBulk} />
              </p>
              <div className="flex flex-col justify-between h-full">
                <div>
                  <p className="text-center mb-4">
                    <span className="text-xl mt-6">Understanding Spam</span>
                  </p>
                  <p className="text-justify text-base">
                    Spam refers to unsolicited, bulk, or indiscriminate messages, typically sent for commercial purposes.
                    SmartConnectX upholds a strict zero-tolerance policy on spam and complies with the Spam Act 2003,
                    a key part of US anti-spam legislation.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div data-aos="fade-down" data-aos-duration="1000" data-aos-easing="ease-in-linear" className="w-full sm:w-1/2 lg:w-1/3 p-2 flex">
            <div className="flex-grow p-6 cursor-pointer transform transition duration-500 hover:scale-105 bg-white">
              <p className="text-4xl text-center mb-4">
                <FontAwesomeIcon icon={faEnvelopesBulk} />
              </p>
              <div className="flex flex-col justify-between h-full">
                <div>
                  <p className="text-center mb-4">
                    <span className="text-xl mt-6">Spam Detection and Filtering</span>
                  </p>
                  <p className="text-justify text-base">
                    SmartConnectX’s messaging systems automatically scan all incoming emails and other messages to identify and filter out those recognized as spam.
                    This process helps ensure that unwanted and potentially harmful communications are blocked before reaching the recipient’s inbox.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div data-aos="fade-down-left" data-aos-duration="1000" data-aos-easing="ease-in-linear" className="w-full sm:w-1/2 lg:w-1/3 p-2 flex">
            <div className="flex-grow p-6 cursor-pointer transform transition duration-500 hover:scale-105 bg-white">
              <p className="text-4xl text-center mb-4">
                <FontAwesomeIcon icon={faEnvelopesBulk} />
              </p>
              <div className="flex flex-col justify-between h-full">
                <div>
                  <p className="text-center mb-4">
                    <span className="text-xl mt-6">Issues with Spam Filtering</span>
                  </p>
                  <p className="text-justify text-base">
                    No spam filter is perfect, so sometimes legitimate messages may be wrongly filtered by SmartConnectX’s systems. If this happens, please contact the recipient through another method. To reduce the chances of your message being caught by spam filters, send it in plain text, avoid attachments, and scan it for malware before sending.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div data-aos="fade-up-right" data-aos-duration="1000" data-aos-easing="ease-in-linear" className="w-full sm:w-1/2 lg:w-1/3 p-2 flex">
            <div className="flex-grow p-6 cursor-pointer transform transition duration-500 hover:scale-105 bg-white">
              <p className="text-4xl text-center mb-4">
                <FontAwesomeIcon icon={faEnvelopesBulk} />
              </p>
              <div className="flex flex-col justify-between h-full">
                <div>
                  <p className="text-center mb-4">
                    <span className="text-xl mt-6">User Spam Policy</span>
                  </p>
                  <p className="text-justify text-base">
                  SmartConnectX offers a feature that allows users to send email or private messages to others. Users are prohibited from using this feature to send unsolicited, bulk, or indiscriminate messages, regardless of whether they are for commercial purposes. Detailed terms and conditions for using this feature are outlined in the provided Document.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in-linear" className="w-full sm:w-1/2 lg:w-1/3 p-2 flex">
            <div className="flex-grow p-6 cursor-pointer transform transition duration-500 hover:scale-105 bg-white">
              <p className="text-4xl text-center mb-4">
                <FontAwesomeIcon icon={faEnvelopesBulk} />
              </p>
              <div className="flex flex-col justify-between h-full">
                <div>
                  <p className="text-center mb-4">
                    <span className="text-xl mt-6">Handling Unwanted Messages from SmartConnectX</span>
                  </p>
                  <p className="text-justify text-base">
                  If you receive a message from SmartConnectX or sent using SmartConnectX’s systems that you believe may be spam, please contact SmartConnectX using the details below. We will investigate the matter promptly.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div data-aos="fade-up-left" data-aos-duration="1000" data-aos-easing="ease-in-linear" className="w-full sm:w-1/2 lg:w-1/3 p-2 flex">
            <div className="flex-grow p-6 cursor-pointer transform transition duration-500 hover:scale-105 bg-white">
              <p className="text-4xl text-center mb-4">
                <FontAwesomeIcon icon={faEnvelopesBulk} />
              </p>
              <div className="flex flex-col justify-between h-full">
                <div>
                  <p className="text-center mb-4">
                    <span className="text-xl mt-6">Updates to Our Anti-Spam Policy</span>
                  </p>
                  <p className="text-justify text-base">
                  SmartConnectX reserves the right to amend this anti-spam policy at any time. Any changes will be reflected in a new version published on this website. 
                  It is important for users to review the policy periodically to stay informed about any updates or modifications.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PolicySection;
