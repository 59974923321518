import about from '../assets/about-us-bg.png';

const AboutSection = () => {
  return (
    <div id="about">
      <div className="relative mt-20 min-h-[650px]">
        <div className="text-center">
          <h2 className="text-3xl sm:text-5xl lg:text-6xl mt-10 lg:mt-20 tracking-wide">
            About{" "}
            <span className="bg-gradient-to-r from-cyan-500 to-cyan-600 text-transparent bg-clip-text">
              Us
            </span>
          </h2>
        </div>
        <div className="flex flex-wrap mt-10 lg:mt-20">
          <div
            data-aos="fade-right"
            data-aos-anchor="#about" data-aos-delay="500"
            data-aos-duration="1000"
            data-aos-easing="ease-in-linear"
            className="w-full sm:w-1/2 lg:w-1/3 p-2 flex"
          >
            <div className="flex-grow unique-card relative p-6 lg:p-12 bg-white shadow-md bg-clip-border cursor-pointer overflow-hidden hover:text-white">
              <div
                className="hover-bg absolute inset-0 bg-cover bg-center opacity-0"
                style={{ backgroundImage: `url(${about})` }}
              ></div>
              <div className="overlay absolute inset-0 bg-black opacity-0"></div>
              <div className="flex flex-col justify-between h-full">
                <div>
                  <p className="text-center mb-4 relative z-10">
                    <span className="text-xl font-semibold mt-6">Promotional Products and Lead Creation</span>
                  </p>
                  <p className="text-justify text-base relative z-10">
                    Our goal is to help you build a professional brand identity,
                    expand your social media presence, attract new business,
                    and enhance communication with your existing clients.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            data-aos="fade-right"
            data-aos-anchor="#about" data-aos-delay="1000"
            data-aos-duration="1000"
            data-aos-easing="ease-in-linear"
            className="w-full sm:w-1/2 lg:w-1/3 p-2 flex"
          >
            <div className="flex-grow unique-card relative p-6 lg:p-12 bg-white shadow-md bg-clip-border cursor-pointer overflow-hidden hover:text-white">
              <div
                className="hover-bg absolute inset-0 bg-cover bg-center opacity-0"
                style={{ backgroundImage: `url(${about})` }}
              ></div>
              <div className="overlay absolute inset-0 bg-black opacity-0"></div>
              <div className="flex flex-col justify-between h-full">
                <div>
                  <p className="text-center mb-4 relative z-10">
                    <span className="text-xl font-semibold mt-6">Social Media & Marketing</span>
                  </p>
                  <p className="text-justify text-base relative z-10">
                    At SmartConnectX, we have a deep understanding of the marketing and social media landscape.
                    We know how to leverage technology to enhance brand engagement and drive sales.
                    We're here to help you maximize the potential of social media,
                    and we always deliver on our promises.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            data-aos="fade-right"
            data-aos-anchor="#about" data-aos-delay="1500"
            data-aos-duration="1000"
            data-aos-easing="ease-in-linear"
            className="w-full sm:w-1/2 lg:w-1/3 p-2 flex"
          >
            <div className="flex-grow unique-card relative p-6 lg:p-12 bg-white shadow-md bg-clip-border cursor-pointer overflow-hidden hover:text-white">
              <div
                className="hover-bg absolute inset-0 bg-cover bg-center opacity-0"
                style={{ backgroundImage: `url(${about})` }}
              ></div>
              <div className="overlay absolute inset-0 bg-black opacity-0"></div>
              <div className="flex flex-col justify-between h-full">
                <div>
                  <p className="text-center mb-4 relative z-10">
                    <span className="text-xl font-semibold mt-6">Leads for businesses</span>
                  </p>
                  <p className="text-justify text-base relative z-10">
                  Our company offers reliable and affordable business leads, 
                  giving you a head start in identifying clients who need your product or service.
                  With our leads, you can focus on what truly matters—growing your business.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
