import Slider from 'react-slick';
import pic1 from '../assets/services/pic-1.jpg';
import pic2 from '../assets/services/pic-2.jpg';
import pic3 from '../assets/services/pic-3.jpg';
import pic4 from '../assets/services/pic-4.jpg';
import pic5 from '../assets/services/pic-5.jpg';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightLeft, faRightLong } from '@fortawesome/free-solid-svg-icons';
import { faRightToBracket } from '@fortawesome/free-solid-svg-icons/faRightToBracket';

const ServicesSection = () => {
  const navigate = useNavigate();
  const handleClick = (link) => {
    navigate(link); // Navigate back to the home page
  };
  const CustomNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} custom-arrow`} // Add custom class here
        style={{ ...style, display: 'block' }}
        onClick={onClick}
      />
    );
  };

  const CustomPrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} custom-arrow`} // Add custom class here
        style={{ ...style, display: 'block' }}
        onClick={onClick}
      />
    );
  };
  const settings = {
    infinite: true,
    dots: true,
    arrows: true,
    centerMode: true,
    centerPadding: '50px',
    speed: 1000,
    slidesToShow: 3,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  const items = [
    { imgSrc: pic1, href: 'sector-leaders', title: 'Sector Leaders', description: 'Our lead generation campaigns are designed to open new business opportunities through a blend of modern and traditional strategies...' },
    { imgSrc: pic2, href: 'expert-prospects', title: 'Expert Prospects', description: 'Boost your response rates by carefully selecting a targeted list of prospects from our extensive business resources...' },
    { imgSrc: pic3, href: 'email-append-solutions', title: 'Email Append Solutions', description: 'In today’s world of instant communication, the internet has become an indispensable tool for marketing, enabling swift long-distance interactions....' },
    { imgSrc: pic4, href: 'sales-insights', title: 'Sales Insights for Tech Marketers', description: 'SmartConnectX empowers businesses with the quality leads essential for a winning sales strategy. Whether you’re an established company or an ambitious startup...' },
    { imgSrc: pic5, href: 'expand-globally', title: 'Expand Globally with Precision', description: 'Expand your markets internationally with ease! At SmartConnectX, we understand the trends and demands of competing on a global scale. We stay updated on...' },
  ];

  return (
    <div id="services">
      <div className="relative mt-20 min-h-[750px]">
        <div className="text-center">
          <h2 className="text-3xl sm:text-5xl lg:text-6xl mt-10 lg:mt-20 tracking-wide">Services</h2>
        </div>
        <div className="mt-10 lg:mt-20">
          <Slider {...settings}>
            {items.map((item, index) => (
              <div key={index} className="p-2">
                <div className="bg-white overflow-hidden shadow-lg max-w-sm mx-auto h-96"> {/* Set a fixed height */}
                  <div className="relative">
                    <img className="w-full h-48 object-cover" src={item.imgSrc} alt="Service Image" />
                  </div>
                  <div className="p-4 h-32 overflow-hidden"> {/* Control content height and overflow */}
                    <h3 className="text-lg font-medium mb-2">{item.title}</h3>
                    <p className="text-gray-600 text-base mb-4 text-justify">{item.description}</p>
                  </div>
                  <div className="p-4">
                    <button onClick={() => handleClick(item.href)} className="bg-gradient-to-r from-cyan-400 to-cyan-600 text-white font-bold py-2 px-4 rounded">
                      Read More <FontAwesomeIcon className='ml-1 text-base' icon={faRightToBracket} />
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>


  );
};

export default ServicesSection;
