export const navItems = [
  { text: "Home", href: "home", offset: -64 },
  { text: "About", href: "about", offset: -160 },
  { text: "Services", href: "services", offset: -160 },
  { text: "Anti Spam Policy", href: "policy", offset: -160 },
  { text: "Facts", href: "facts", offset: -160 },
  { text: "FAQs", href: "faqs", offset: -160 },
  { text: "Work With Us", href: "work-with-us", offset: -160 },
  { text: "Our Customers", href: "our-customers", offset: -160 },
  { text: "Why Us", href: "why-us", offset: -160 },
  { text: "Contact", href: "contact", offset: -160 },
];