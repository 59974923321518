import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import pic2 from '../../assets/services/pic-2.jpg';
const ExpertProspects = () => {
    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate(-1); // Navigate back to the home page
    };
    return (
        <>
            <Helmet>
                <title>Expert Prospects | Smart Connect X</title>
                <meta name="description" content="Enhance your sales figures with SmartConnectX's targeted lead generation services. Access high-profile professionals, specialists, and executives from our extensive and reliable databases. Optimize your marketing efforts with precise and accurate leads." />
                <meta name="keywords" content="lead generation, high-profile professionals, targeted lists, SmartConnectX, B2B marketing, sales leads, professional mailing lists, data accuracy" />
                <meta property="og:title" content="Expert Prospects | Smart Connect X" />
                <meta property="og:description" content="Discover SmartConnectX's lead generation services designed to boost your response rates. Access verified data resources, target high-profile professionals, and optimize your marketing efforts with our accurate and reliable databases." />
                <meta property="og:url" content="https://smartconnectx.com/sector-leaders" />
                <meta property="og:type" content="website" />
            </Helmet>
            <div className="max-w-7xl mx-auto pt-20 px-6">
                <div className="relative mt-20 border-neutral-800 min-h-[800px]">
                    <div className="flex items-center justify-between mt-10 lg:mt-20">
                        <div className="text-left">
                            <h2 className="text-3xl sm:text-5xl lg:text-6xl tracking-wide">
                                <FontAwesomeIcon icon={faArrowAltCircleLeft} onClick={handleBackClick} />
                            </h2>
                        </div>
                        <div className="text-center flex-1">
                            <h2 className="text-3xl sm:text-5xl lg:text-6xl tracking-wide">
                                Expert Prospects
                            </h2>
                        </div>
                    </div>
                    <div className="flex flex-wrap mt-10 lg:mt-20">
                        <img className="w-full h-48 object-cover py-2" src={pic2} alt="Service Image" />
                        <div className="py-2 space-y-2">
                            <p>Boost your response rates by carefully selecting a targeted list of prospects from our extensive business resources. If you're searching for high-profile professionals, specialists, or executives, SmartConnectX is your go-to partner. We provide data resources designed to significantly enhance your sales figures. Our track record in identifying and engaging professionals across virtually every industry is unmatched. At SmartConnectX, we're dedicated to generating the most relevant leads for your business using the most effective methods. Our team of experts continuously monitors and evaluates the leads we deliver, ensuring they align with your business objectives. Our mailing lists are derived from unique and reliable sources, including both public and private data repositories.</p>
                            <p>SmartConnectX offers a diverse range of personnel lists, from engineers to accountants, architects to librarians. We are committed to delivering precise information that generates high-quality leads, allowing us to fine-tune our lead scoring criteria to meet your specific needs. We also optimize your bulk email and SMS campaigns based on your current business environment, ensuring your promotional marketing efforts yield maximum results.</p>
                            <p>Our proprietary databases, containing millions of records, can be accessed within days. What sets us apart is our meticulous process of cross-referencing each record, leaving no room for error. Our data teams work tirelessly to ensure that every list you purchase meets the highest standards of accuracy.</p>
                            <p>Key advantages of our professional mailing lists include enhancing your B2B marketing efforts by pinpointing businesses that need your products or services. At SmartConnectX, we recognize that today’s competitive landscape requires a partner who understands your company’s unique identity and market position. We offer innovative lead generation services that maximize every sales opportunity.</p>
                            <p>With our expertise and dedicated team, we ensure that all potential revenue opportunities are identified and capitalized on. We specialize in connecting you with key decision-makers across the globe, whether you’re exploring new markets or seeking opportunities to expand your product or service reach.</p>
                            <p>Our partners value our ability to quickly source high-quality leads, manage multiple users, and offer competitive pricing. Our comprehensive databases, available in just a few days, double your reach to potential clients. Get in touch with us today to elevate your B2B sales funnel with top-tier telemarketing, appointment setting, professional B2B databases, and digital lead generation services.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ExpertProspects;
