import { useState } from 'react';
import { AccordionItem } from './custom/Accordion'
const FAQSection = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const faqItems = [
        {
            question: 'How We Compile Our Lists?',
            answer: 'Our lists are compiled from a variety of sources including membership rosters, registrations, public records, licenses, corporate and executive directories, postal records, surveys, questionnaires, census data, student lists, birth records, voter registrations, telemarketing efforts, mail and telephone inquiries, purchase information, telephone directories, rebate coupons, subscriber order forms, warranty registrations, entry forms, credit and financial data, SEC listings, institutional information, conference, trade show, and seminar registrations, government records, opt-in email responses, applications, and rating and licensing boards. All data is gathered from proprietary, self-reported sources or public records, and is obtained legally and ethically, adhering to strict industry rules, regulations, and guidelines.'
        },
        {
            question: 'How Often We Update Our Database?',
            answer: 'Our compiled lists are updated monthly or quarterly using original source documentation, address changes from postal, subscription, and credit sources, as well as through pander files, suppression files, and telemarketing efforts.'
        },
        {
            question: 'What Is The SmartConnectX Guarantee?',
            answer: 'SmartConnectX Solutions guarantees the deliverability and accuracy of all appended records. If you receive any bounce-backs or encounter erroneous emails, we will issue a credit for those records. Please note that, due to changes such as people moving or canceling email accounts, this guarantee is valid for 14 days from the date you receive your newly appended data.'
        },
        {
            question: 'How Quickly Can We Complete the Append Process?',
            answer: 'We offer some of the fastest turnaround times for data appends. After receiving your data, we typically complete the append process and return your updated data within 7 to 10 days. In urgent cases, we can expedite this to as little as 24 hours. Our direct partnership with the nation’s largest data consortium allows us to streamline the process, avoiding multiple handoffs and ensuring quicker, more efficient service.'
        },
        {
            question: 'How Long Does List Delivery Take?',
            answer: 'We typically deliver lists within 5 working days, which includes the verification process and the addition of any extra contacts if necessary. For urgent requests, we can expedite delivery to 24 hours for an additional fee.'
        },
        {
            question: 'How Long Do Email Addresses Remain Valid?',
            answer: 'Typically, an email database experiences a 1-2% turnover each month due to people moving, changing jobs, or switching Internet providers. This highlights the importance of choosing an append provider with regularly updated data. We also offer ongoing services to help keep your email records current and accurate.'
        },
        {
            question: 'How Is Pricing Determined?',
            answer: 'Our pricing is based on the volume of records or specific criteria you require. Generally, acquiring more records reduces the cost per contact. We do, however, have a minimum purchase order of $2,500 for any project.'
        }
    ];

    return (

        <div id="faqs">
            <div className="relative mt-20 min-h-[800px]">
                <div className="text-center">
                    <h2 className="text-3xl sm:text-5xl lg:text-6xl mt-10 lg:mt-20 tracking-wide">
                        Frequently Asked{" "}
                        <span className="bg-gradient-to-r from-cyan-500 to-cyan-600 text-transparent bg-clip-text">
                            Questions
                        </span>
                    </h2>
                </div>
                <div className="flex flex-wrap mt-10 lg:mt-20">
                    <ul className="mt-3 flex flex-col space-y-4">
                        {faqItems.map((item, index) => (
                            <AccordionItem
                                key={index}
                                idx={index}
                                question={item.question}
                                answer={item.answer}
                                activeIndex={activeIndex}
                                setActiveIndex={setActiveIndex}
                            />
                        ))}
                    </ul>
                </div>
            </div>
        </div>


    );
};

export default FAQSection;
