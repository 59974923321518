import { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
export const AccordionItem = ({ question, answer, idx, activeIndex, setActiveIndex }) => {
    const contentRef = useRef(null);

    const handleClick = () => {
        setActiveIndex(activeIndex === idx ? null : idx);
    };

    return (
        <li className="bg-white my-2">
            <h2
                onClick={handleClick}
                className="flex flex-row justify-between items-center p-3 font-semi-bold cursor-pointer"
            >
                <span>{question}</span>
                <FontAwesomeIcon
                    icon={faChevronDown}
                    className={`fill-current text-cyan-500 h-6 w-6 transform transition-transform duration-700 ${activeIndex === idx ? 'rotate-180' : ''}`}
                />
            </h2>
            <div
                ref={contentRef}
                style={{
                    maxHeight: activeIndex === idx ? `${contentRef.current.scrollHeight}px` : '0',
                    transition: 'max-height 0.5s ease-in-out'
                }}
                className="border-l-2 border-cyan-500 overflow-hidden"
            >
                <p className="p-3 text-justify text-base">{answer}</p>
            </div>
        </li>
    );
};