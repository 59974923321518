import { useState } from 'react';
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';
const OurCustomers = () => {
    const [startCount, setStartCount] = useState(false);
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.5, // Adjust threshold as needed
    });
    if (inView && !startCount) {
        setStartCount(true);
    }
    return (
        <div id="our-customers" ref={ref}>
            <div className="relative z-10 flex flex-col items-center justify-center h-full text-white text-center space-y-8">
                {startCount && <>
                    <h2 className="text-3xl sm:text-5xl lg:text-6xl tracking-wide mb-4">Our Customers</h2>
                    <div className="flex flex-col gap-10 md:flex-row md:space-x-8 md:space-y-0">
                        <div className='md:space-y-2'>
                            <h3 className="text-6xl font-bold"> <CountUp start={0} end={1000} duration={3} suffix="+"/></h3>
                            <p className="text-2xl font-bold">North America</p>
                        </div>
                        <div className='md:space-y-2'>
                            <h3 className="text-6xl font-bold"><CountUp start={0} end={4800} duration={3} suffix="+"/></h3>
                            <p className="text-2xl font-bold">World Wide</p>
                        </div>
                    </div>
                </>
                }
            </div>
        </div>
    );
};

export default OurCustomers;