import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Navbar from "./components/Navbar";
import HeroSection from "./components/HeroSection";
import AboutSection from "./components/About";
import ServicesSection from "./components/Services";
import FooterSection from "./components/Footer";
import HeroImage2 from './assets/hero-image-2.jpg';
import Customer from './assets/our-customers.jpg';
import FactsSection from "./components/Facts";
import PolicySection from "./components/Policy";
import ContactSection from "./components/Contact";
import FAQSection from "./components/FAQ";
import WhyUsSection from "./components/WhyUs";
import OurCustomers from './components/OurCustomers';
import WorkWithUsSection from "./components/WorkWithUS";
import SectorLeaders from './components/services/SectorLeaders';
import EmailAppendSolutions from './components/services/EmailAppendSolutions';
import ExpandGlobally from './components/services/ExpandGlobally';
import ExpertProspects from './components/services/ExpertProspects';
import SalesInsights from './components/services/SalesInsights';


function App() {
  const divStyle = {
    backgroundImage: `url(${HeroImage2})`,
  };

  const Home = () => (
    <>
      <Helmet>
        <title>Smart Connect X</title>
        <meta name="description" content="SmartConnectX helps you build a professional brand identity, expand your social media presence, attract new business, and enhance communication with existing clients. We offer reliable business leads, comprehensive anti-spam policies, and expert marketing strategies." />
        <meta name="keywords" content="SmartConnectX, lead generation, promotional products, social media marketing, business leads, anti-spam policy, marketing strategies" />
        <meta property="og:title" content="About SmartConnectX: Your Partner in Lead Generation and Marketing" />
        <meta property="og:description" content="Discover how SmartConnectX supports your business with top-notch lead generation, promotional products, social media marketing, and a strict anti-spam policy. Learn more about our expertise in helping you succeed in the long run." />
        <meta property="og:url" content="https://smartconnectx.com" />
        <meta property="og:type" content="website" />
      </Helmet>
      <Navbar />
      <section className="relative w-full h-screen bg-cover bg-center lg:bg-fixed fixed" style={divStyle} id="home">
        <div className="flex items-center justify-center w-full h-full">
          <HeroSection />
        </div>
      </section>
      <section className="bg-slate-200">
        <div className="max-w-7xl mx-auto pt-20 px-6">
          <AboutSection />
        </div>
      </section>
      <section className="">
        <div className="max-w-7xl mx-auto pt-20 px-6">
          <ServicesSection />
        </div>
      </section>
      <section className="bg-slate-200">
        <div className="max-w-7xl mx-auto pt-20 px-6">
          <PolicySection />
        </div>
      </section>
      <section className="">
        <div className="max-w-7xl mx-auto pt-20 px-6">
          <FactsSection />
        </div>
      </section>
      <section className="bg-slate-200">
        <div className="max-w-7xl mx-auto pt-20 px-6">
          <FAQSection />
        </div>
      </section>
      <section className="">
        <div className="max-w-7xl mx-auto pt-20 px-6">
          <WorkWithUsSection />
        </div>
      </section>
      <section className="bg-sky-200" style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${Customer})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}>
        <div className="max-w-7xl mx-auto py-20 px-6">
          <OurCustomers />
        </div>
      </section>
      <section className="bg-slate-200">
        <div className="max-w-7xl mx-auto pt-20 px-6">
          <WhyUsSection />
        </div>
      </section>
      <section className="">
        <div className="max-w-7xl mx-auto pt-20 px-6">
          <ContactSection />
        </div>
      </section>
      <section className="bg-gray-800">
        <FooterSection />
      </section>
    </>
  );

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/sector-leaders" element={<SectorLeaders />} />
        <Route path="/email-append-solutions" element={<EmailAppendSolutions />} />
        <Route path="/expand-globally" element={<ExpandGlobally />} />
        <Route path="/expert-prospects" element={<ExpertProspects />} />
        <Route path="/sales-insights" element={<SalesInsights />} />
      </Routes>
    </Router>
  );
}

export default App;
