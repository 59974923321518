import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import pic5 from '../../assets/services/pic-5.jpg';
const ExpandGlobally = () => {
    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate(-1); // Navigate back to the home page
    };
    return (
        <>
            <Helmet>
                <title>Expand Globally with Precision | Smart Connect X</title>
                <meta name="description" content="Unlock global markets with SmartConnectX’s international mailing lists and marketing expertise. Access reliable global leads, optimize your strategies, and boost your sales worldwide with our innovative solutions." />
                <meta name="keywords" content="international marketing, global email lists, SmartConnectX, global leads, marketing expansion, direct marketing, email marketing" />
                <meta property="og:title" content="Expand Globally with Precision | Smart Connect X" />
                <meta property="og:description" content="Expand your global reach with SmartConnectX’s expertise in international marketing. Access accurate global leads, optimize your strategies, and enhance your sales with our comprehensive solutions." />
                <meta property="og:url" content="https://smartconnectx.com/expand-globally" />
                <meta property="og:type" content="website" />
            </Helmet>
            <div className="max-w-7xl mx-auto pt-20 px-6">
                <div className="relative mt-20 border-neutral-800 min-h-[800px]">
                    <div className="flex items-center justify-between mt-10 lg:mt-20">
                        <div className="text-left">
                            <h2 className="text-3xl sm:text-5xl lg:text-6xl tracking-wide">
                                <FontAwesomeIcon icon={faArrowAltCircleLeft} onClick={handleBackClick} />
                            </h2>
                        </div>
                        <div className="text-center flex-1">
                            <h2 className="text-3xl sm:text-5xl lg:text-6xl tracking-wide">
                                Expand Globally with Precision
                            </h2>
                        </div>
                    </div>
                    <div className="flex flex-wrap mt-10 lg:mt-20">
                        <img className="w-full h-48 object-cover py-2" src={pic5} alt="Service Image" />
                        <div className="py-2 space-y-2">
                            <p>Expand your markets internationally with ease! At SmartConnectX, we understand the trends and demands of competing on a global scale. We stay updated on emerging developments, including local market shifts, to help you succeed worldwide. With years of experience in tracking down deliverable addresses in some of the hottest global markets, you might discover higher response rates in other countries. Factors such as less direct mail, cultural differences, and excitement about direct purchases contribute to this. Our international mailing lists allow you to access this vast market and enhance your sales. We ensure that our global network of leads is both accurate and comprehensive, making our international email lists among the most reliable sources for global email marketing.</p>
                            <p>At SmartConnectX, we have innovative ideas to drive your success. Our expertise in direct, email, and online marketing helps you find new prospects and customers, turn your house file into a profit center through effective list management, and plan successful direct marketing campaigns. We aim to maximize your marketing investments with cost-effective computer services, becoming your strategic marketing partner to boost both direct and online marketing profits.</p>
                            <p>With our deep knowledge and experience, we are committed to helping you optimize your marketing strategies and achieve significant results. Partner with SmartConnectX to unlock new opportunities and maximize your marketing effectiveness on a global scale.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ExpandGlobally;
