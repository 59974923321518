import { navItems } from "../constants";
import { Menu, X } from "lucide-react";
import { useState } from "react";
import logo from "../assets/logo.png";
import { Link } from 'react-scroll';
const Navbar = () => {
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);
  const toggleNavbar = () => {
    setMobileDrawerOpen(!mobileDrawerOpen);
  };
  return (
    <nav className="sticky top-0 z-50 py-2 backdrop-blur-sm">
      <div className="container px-4 mx-auto relative lg:text-base">
        <div className="flex justify-between items-center">
          <div className="flex items-center flex-shrink-0">
            <img className="h-12 w-12 mr-2" src={logo} alt="Logo" />
          </div>
          <ul className="hidden lg:flex ml-14 space-x-12">
            {navItems.map((item, index) => (
              <li key={index} className="cursor-pointer custom-link">
                <Link
                  className="hover:cyan-500"
                  activeClass="active"
                  to={item.href}
                  spy={true}
                  smooth={true}
                  offset={item.offset}
                  duration={500}
                >{item.text}
                </Link>
              </li>
            ))}
          </ul>
          <div className="lg:hidden md:flex flex-col justify-end">
            <button onClick={toggleNavbar}>
              {mobileDrawerOpen ? <X /> : <Menu />}
            </button>
          </div>
        </div>
        {mobileDrawerOpen && (
          <div className="fixed right-0 z-20 bg-neutral-900 text-white w-full p-12 flex flex-col justify-center items-center lg:hidden">
            <ul>
              {navItems.map((item, index) => (
                <li key={index} className="py-4 cursor-pointer custom-link">
                  {/* <a href={item.href}>{item.label}</a> */}
                  <Link
                    className="hover:cyan-500"
                    activeClass="active"
                    to={item.href}
                    spy={true}
                    smooth={true}
                    offset={item.offset}
                    duration={500}
                  >{item.text}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;