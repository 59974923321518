import { Link } from 'react-scroll';
const HeroSection = () => {
    return (
        <div className="flex flex-col items-center mt-6 lg:mt-20">
            <h1 data-aos="fade-zoom-in" data-aos-delay="500" data-aos-easing="ease-in-sine" data-aos-duration="1000" className="text-4xl sm:text-5xl lg:text-6xl text-center text-black tracking-wide font-bold italic">
                SMART
                <span className="bg-gradient-to-r from-cyan-500 to-cyan-600 text-transparent bg-clip-text">
                    {" "}
                    CONNECT X
                </span>
            </h1>
            <p data-aos="fade-down" data-aos-delay="1000"
                data-aos-duration="1000" className="mt-10 text-lg text-center max-w-4xl">
                "The goal of the professionals at SmartConnectX is to help you succeed in the long run by expanding and retaining your audience."
            </p>
            <div data-aos="fade-down" data-aos-delay="2000"
                data-aos-duration="1500" className="flex justify-center my-10">
                <Link
                    className="animate-bounce bg-gradient-to-r from-cyan-400 to-cyan-600 py-3 px-4 mx-3 rounded-full text-white cursor-pointer" style={{ animationDelay: '1.8s' }}
                    to="contact"
                    smooth={true}
                    duration={500}
                    offset={-160}
                >
                    Get in touch
                </Link>
            </div>
        </div>
    );
};
export default HeroSection;

