import { useState } from 'react';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import { toast, Toaster } from 'react-hot-toast';
import logo from '../assets/toast-logo.png';
import { faAt, faLocationDot, faPhoneVolume } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ContactSection = () => {
    const [recaptchaToken, setRecaptchaToken] = useState(null);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        email: '',
        name: '',
        message: '',
    });
    const handleRecaptchaChange = (token) => {
        setRecaptchaToken(token);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (recaptchaToken) {
            try {
                await axios.post('https://api.smartconnectx.com', formData).then(response => {
                    toast.custom((t) => (
                        <div
                            className={`${t.visible ? 'animate-enter' : 'animate-leave'
                                } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
                        >
                            <div className="flex-1 w-0 p-4 bg-green-500 rounded-l-lg">
                                <div className="flex items-start">
                                    <div className="flex-shrink-0 pt-0.5">
                                        <img
                                            className="h-14 w-14 rounded-full"
                                            src={logo}
                                        />
                                    </div>
                                    <div className="ml-3 flex-1">
                                        <p className="text-xl font-medium text-white">
                                            Hi {formData.name},
                                        </p>
                                        <p className="mt-1 text-sm text-white">
                                            We just got your message!
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="flex border-l border-gray-200">
                                <button
                                    type='button'
                                    onClick={() => toast.dismiss(t.id)}
                                    className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-red-500 focus:outline-none focus:ring-2 focus:ring-red-200"
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    ),
                        setFormData({
                            name: '',
                            email: '',
                            message: '',
                        })
                    )
                }).finally(() => {
                    setLoading(false);
                });
            } catch (error) {
                setLoading(false);
                toast.error("We're unable to send your message, Please try again a later!")
            }
        } else {
            toast.error("Please complete the reCAPTCHA.")
        }
    };
    return (
        <div id="contact">
            <div className="relative mt-20 min-h-[600px]">
                <div className="text-center">
                    <h2 className="text-3xl sm:text-5xl lg:text-6xl mt-10 lg:mt-20 tracking-wide">
                        Contact{" "}
                        <span className="bg-gradient-to-r from-cyan-500 to-cyan-600 text-transparent bg-clip-text">
                            Us
                        </span>
                    </h2>
                </div>
                <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-20 lg:px-8">
                    <div className="grid sm:grid-cols-1 lg:grid-cols-3 gap-12 mb-12">
                        <div className="flex flex-col items-center py-4">
                            <FontAwesomeIcon className='text-cyan-500 text-4xl' icon={faLocationDot} />
                            <div className="mt-4 text-center">
                                <h4 className="text-gray-800 text-base font-bold">Address</h4>
                                <p className="text-sm text-gray-500 mt-2 text-left"><span className='font-bold'>US HQ</span> : 1235 1/2 5th Ave, Los Angeles, CA 90019, USA</p>
                                <p className="text-sm text-gray-500 mt-2 text-left"><span className='font-bold'>Others</span> : India</p>
                            </div>
                        </div>

                        <div className="flex flex-col items-center py-4">
                            <FontAwesomeIcon className='text-cyan-500 text-4xl' icon={faPhoneVolume} />
                            <div className="mt-4 text-center">
                                <h4 className="text-gray-800 text-base font-bold">Call us</h4>
                                <p className="text-sm text-gray-500 mt-2"> +91 999999999</p>
                            </div>
                        </div>

                        <div className="flex flex-col items-center py-4">
                            <FontAwesomeIcon className='text-cyan-500 text-4xl' icon={faAt} />
                            <div className="mt-4 text-center">
                                <h4 className="text-gray-800 text-base font-bold">Email</h4>
                                <p className="text-sm text-gray-500 mt-2">info@smartconnectx.com</p>
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                            <div className="overflow-hidden">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3305.804474936!2d-118.3244701237614!3d34.04888631790673!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2b88aa453636d%3A0x22782b74e75a4d9c!2s1235%201%2F2%205th%20Ave%2C%20Los%20Angeles%2C%20CA%2090019%2C%20USA!5e0!3m2!1sen!2sin!4v1723615814120!5m2!1sen!2sin"
                                    width="100%" height="560" allowFullScreen="" loading="lazy"></iframe>
                            </div>
                            <div className="px-2 mx-auto w-full">
                                <form onSubmit={handleSubmit} className="space-y-8 ">
                                    <div>
                                        <label htmlFor="email" className="block mb-2 text-base font-medium text-gray-900">Your email</label>
                                        <input value={formData.email}
                                            onChange={handleChange} name="email" type="email" id="email" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5" placeholder="Enter your email" required />
                                    </div>
                                    <div>
                                        <label htmlFor="name" className="block mb-2 text-base font-medium text-gray-900">Your name</label>
                                        <input value={formData.name}
                                            onChange={handleChange} name="name" type="text" id="name" className="block p-3 w-full text-base text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500" placeholder="Enter your name" required />
                                    </div>
                                    <div>
                                        <label htmlFor="message" className="block mb-2 text-base font-medium text-gray-900">Your message</label>
                                        <textarea value={formData.message}
                                            onChange={handleChange} name="message" id="message" rows="4" className="resize-none block p-2.5 w-full text-base text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500" placeholder="Enter your message..."></textarea>
                                    </div>
                                    <ReCAPTCHA
                                        sitekey="6Le0wyUqAAAAABDqAdcTFnmp_4QyhdJ-FB6sYhZu"
                                        onChange={handleRecaptchaChange}
                                    />
                                    <button disabled={loading} type="submit" className="bg-gradient-to-r from-cyan-400 to-cyan-600 w-full text-center text-white focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2">
                                        {loading ? <>
                                            <svg aria-hidden="true" role="status" className="inline w-4 h-4 me-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                            </svg> Sending...</>
                                            : <>Send Message</>
                                        }
                                    </button>
                                    <Toaster />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactSection;
