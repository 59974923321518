import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const FactsSection = () => {
    const factItems = [
        {
            text: "Every day, 247 billion emails are sent, equating to one email every 0.00000035 seconds."
        },
        {
            text: "Google Trends shows that email is more popular than Elvis, The Beatles, Chocolate, Beer, Justin Bieber, and Harry Potter."
        },
        {
            text: "79% of people use their smartphones to read emails, surpassing the percentage who use them for making calls."
        },
        {
            text: "Email is currently the fastest, most flexible, cost-effective, personalized, and targeted communication tool available."
        },
        {
            text: "Customer retention via email is one-tenth the cost of using direct mail."
        },
        {
            text: "62% of permission-based email recipients are eager or curious to read them. Email remains the dominant Internet application, outpacing even the World Wide Web in usage."
        },
        {
            text: "B2B emails with only 1 or 2 words in the subject line achieve higher open rates than those with longer subject lines."
        },
        {
            text: "Email marketing is widely recognized as a key tool for promoting businesses and boosting sales globally, with about 90% of campaigns generating results within 48 hours."
        },
        {
            text: "Nearly 147 million people in the U.S. check their emails daily."
        },
        {
            text: "Email marketing generates an ROI exceeding $583 billion, surpassing Microsoft’s market value, and its total revenue could cover the entire U.S. National Debt."
        },
        {
            text: "The global internet user base is approximately 1.9 billion, outnumbering the combined population of the European Union, the USA, and China."
        },
        {
            text: "The optimal times to send marketing emails are between 10 am and 1 pm on Tuesdays, Wednesdays, and Thursdays."
        },
    ];
    return (
        <div id="facts">
            <div className="relative mt-20 min-h-[850px]">
                <div className="text-center">
                    <h2 className="text-3xl sm:text-5xl lg:text-6xl mt-10 lg:mt-20 tracking-wide">
                        Facts
                    </h2>
                </div>
                <div className="flex flex-wrap mt-10 lg:mt-20">
                    <ul className="mt-3 flex flex-col space-y-4">
                        {factItems.map((item, index) => (
                            <li key={index} className="flex">
                                <FontAwesomeIcon className="h-6 pr-4 text-cyan-500" icon={faCircleInfo} />
                                <p className="text-base">{item.text}</p>
                            </li>
                        ))}
                    </ul>
                </div>

            </div>
        </div>
    );
};

export default FactsSection;
