const WhyUsSection = () => {
    return (
        <div id="why-us">
            <div className="relative min-h-[400px]">
                <div className="text-center">
                    <h2 className="text-3xl sm:text-5xl lg:text-6xl mt-10 lg:mt-20 tracking-wide">
                        Why{" "}
                        <span className="bg-gradient-to-r from-cyan-500 to-cyan-600 text-transparent bg-clip-text">
                            Us
                        </span>
                    </h2>
                </div>
                <div className="flex flex-wrap mt-10 lg:mt-20">
                    <p className="text-justify">
                        SmartConnectX caters to international markets and a wide range of industries. To assist our clients in reaching a particular audience with their message, SmartConnectX's team of market research specialists, data producers, and sales intelligence departments collaborates with them. We provide precise, highly targeted lead lists of businesses and connections for their targeted advertising initiatives.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default WhyUsSection;
