import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import pic1 from '../../assets/services/pic-1.jpg';
const SectorLeaders = () => {
    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate(-1); // Navigate back to the home page
    };
    return (
        <>
            <Helmet>
                <title>Sector Leaders | Smart Connect X</title>
                <meta name="description" content="SmartConnectX offers lead generation services that combine modern and traditional strategies to open new business opportunities. Connect with professionals across 120+ industries globally and access verified contact details to boost your sales efforts." />
                <meta name="keywords" content="lead generation, business opportunities, professional contacts, SmartConnectX, sales leads, global data partners, industry leads" />
                <meta property="og:title" content="Sector Leaders | Smart Connect X" />
                <meta property="og:description" content="Discover SmartConnectX's lead generation services that help you connect with over 120 industries globally. Access verified contact details and target your audience effectively to drive sales." />
                <meta property="og:url" content="https://smartconnectx.com/expert-prospects" />
                <meta property="og:type" content="website" />
            </Helmet>
            <div className="max-w-7xl mx-auto pt-20 px-6">
                <div className="relative mt-20 border-neutral-800 min-h-[800px]">
                    <div className="flex items-center justify-between mt-10 lg:mt-20">
                        <div className="text-left">
                            <h2 className="text-3xl sm:text-5xl lg:text-6xl tracking-wide">
                                <FontAwesomeIcon icon={faArrowAltCircleLeft} onClick={handleBackClick} />
                            </h2>
                        </div>
                        <div className="text-center flex-1">
                            <h2 className="text-3xl sm:text-5xl lg:text-6xl tracking-wide">
                                Sector Leaders
                            </h2>
                        </div>
                    </div>

                    <div className="flex flex-wrap mt-10 lg:mt-20">
                        <img className="w-full h-48 object-cover py-2" src={pic1} alt="Service Image" />
                        <div className="py-2 space-y-2">
                            <p>Our lead generation campaigns are designed to open new business
                                opportunities through a blend of modern and traditional strategies.
                                We take care of the hard work by identifying and qualifying potential leads,
                                enabling your sales team to focus on what they do best: closing deals.
                                We help you connect with over 120 major industries, from agriculture and aerospace to real estate.
                                Thanks to our extensive network of global data partners,
                                we can extend your marketing reach across Europe, Asia, Australia, and North America.</p>
                            <p>With SmartConnectX, you can access verified contact details for professionals in your target industry.
                                Acquiring an email list with accurate and relevant information has never been simpler,
                                making it easier for you to forge real connections.
                                Our databases allow you to precisely target your audience,
                                leading to more successful deals and increased sale</p>
                            <p> We specialize in generating highly responsive leads tailored to your product or service.
                                Our marketing analysts conduct a thorough review of your campaign,
                                profiling your most engaged customers.
                                Using this insight, SmartConnectX creates a customized prospect list that maximizes your sales efforts.
                                We provide targeted leads to fuel your business growth! </p>
                        </div>
                    </div>
                </div>
            </div>

        </>

    );
};

export default SectorLeaders;
