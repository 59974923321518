import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import pic4 from '../../assets/services/pic-4.jpg';
const SalesInsights = () => {
    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate(-1); // Navigate back to the home page
    };
    return (
        <>
            <Helmet>
                <title>Sales Insights for Tech Marketers | Smart Connect X</title>
                <meta name="description" content="SmartConnectX provides businesses with high-quality leads essential for effective sales strategies. From startups to established companies, access powerful sales intelligence and connect with decision-makers across industries to drive growth." />
                <meta name="keywords" content="quality leads, sales strategy, SmartConnectX, business development, sales intelligence, decision-makers, targeted datasets, marketing campaigns" />
                <meta property="og:title" content="Sales Insights for Tech Marketers | Smart Connect X" />
                <meta property="og:description" content="Empower your business with SmartConnectX’s quality leads. Identify key decision-makers, break into new markets, and secure larger accounts with our vast databases and sales intelligence." />
                <meta property="og:url" content="https://smartconnectx.com/sales-insights" />
                <meta property="og:type" content="website" />
            </Helmet>
            <div className="max-w-7xl mx-auto pt-20 px-6">
                <div className="relative mt-20 border-neutral-800 min-h-[800px]">
                    <div className="flex items-center justify-between mt-10 lg:mt-20">
                        <div className="text-left">
                            <h2 className="text-3xl sm:text-5xl lg:text-6xl tracking-wide">
                                <FontAwesomeIcon icon={faArrowAltCircleLeft} onClick={handleBackClick} />
                            </h2>
                        </div>
                        <div className="text-center flex-1">
                            <h2 className="text-3xl sm:text-5xl lg:text-6xl tracking-wide">
                                Sales Insights for Tech Marketers
                            </h2>
                        </div>
                    </div>
                    <div className="flex flex-wrap mt-10 lg:mt-20">
                        <img className="w-full h-48 object-cover py-2" src={pic4} alt="Service Image" />
                        <div className="py-2 space-y-2">
                            <p>SmartConnectX empowers businesses with the quality leads essential for a winning sales strategy. Whether you’re an established company or an ambitious startup, we help you quickly identify the right business development opportunities and contacts to drive growth. With powerful sales intelligence, your team can break into new markets and secure larger accounts. Our vast databases profile decision-makers at mid-market to large organizations across all major industries, making it easy to find the companies you need from various geographic regions.</p>
                            <p>We’ve partnered with leading technology firms like Autodesk, NEC, NTT Group, Ansys, Airwatch, and HCL to help them expand into new markets, boost product awareness, and generate targeted datasets for effective marketing campaigns. At SmartConnectX, we assist in finding new leads, optimizing processes, and creating new opportunities. With deep insights into a company’s revenue and spending patterns, our team can quickly determine the most promising prospects, always ready to support your business goals.</p>
                            <p>Our team of diverse and knowledgeable data experts at SmartConnectX excels in creative problem-solving and actively listens to customer needs. We provide verified business and contact information for key executives, ensuring your email marketing campaigns reach the right audience. By aligning marketing efforts with relevant leads, we help deliver impactful results and a strong return on investment.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SalesInsights;
