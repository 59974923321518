const WorkWithUsSection = () => {
    return (
        <div id="work-with-us">
            <div className="relative min-h-[400px]">
                <div className="text-center">
                    <h2 className="text-3xl sm:text-5xl lg:text-6xl mt-10 lg:mt-20 tracking-wide">
                        Work With{" "}
                        <span className="bg-gradient-to-r from-cyan-500 to-cyan-600 text-transparent bg-clip-text">
                            Us
                        </span>
                    </h2>
                </div>
                <div className="flex flex-wrap mt-10 lg:mt-20">
                    <p className="text-justify">
                        SmartConnectX offers a wide range of company postal and email lists, data services, and direct marketing solutions to assist businesses in finding new business prospects. We offer reasonably priced solutions that assist our clients in establishing connections with their target audiences, using the most precise lists assembled from the most reliable sources. Clients may complete their data and direct marketing projects on schedule every time with the support of Sales' and Support's prompt response times.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default WorkWithUsSection;
